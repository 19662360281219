<template>
<div>
  <div v-if="isLoading">
    <CanPayLoader/>
  </div>
  <div class="container">

    <div>
      <div v-for="(uploaded_file, index) in uploaded_files" class="row btn-row-space">
        <div class="col-12">
          <div v-if="!uploaded_file.isPdf">
            <img class="preview" :src="uploaded_file.url" />
          </div>
          <div v-else>
            <img class="pdf-preview" src="../../assets/images/pdf.png" />
            <div class="col-12 text-center">
              <label
                style="color: white; font-size: 1.5rem; margin-left: 0.5rem"
              >
                {{ uploaded_file.fileName }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center p-1 mt-1" v-if="uploaded_files.length > 5">
        <label style="color: white; font-size: 1rem;  margin-left: 0.5rem">
          Supported file formats are png , jpeg , jpg , pdf <br/>
          Maximum file size = 10 MB
        </label>
      </div>

      <!-- image upload section -->
      <div v-if="upload" class="row btn-row-space">
        <div class="col-12" v-if="!addMore">
          <div class="upload-document" @click="openCamera">
            <input
              id="open_camera"
              @change="onFileChange"
              type="file"
              ref="camera"
              accept="image/*,application/pdf"
              capture
              hidden
            />
            <span>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 6.6 8.3"
                style="enable-background: new 0 0 6.6 8.3; margin-top: -9px"
                xml:space="preserve"
                fill="#ffffff"
                width="125"
                height="125"
              >
                <rect />
                <path
                  d="M4.4,2.8L4.4,2.8C4.3,2.8,4.3,2.8,4.4,2.8L4.3,3.2H4l0,0C4,3.2,3.9,3.2,3.9,3.3c0,0,0,0.1,0.1,0.1h0.3v0.3
	c0,0.1,0,0.1,0.1,0.1s0.1,0,0.1-0.1l0,0V3.4h0.3c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1-0.1-0.1l0,0H4.5V2.9C4.5,2.8,4.5,2.8,4.4,2.8
	L4.4,2.8L4.4,2.8z"
                />
                <path
                  d="M3.8,2.8L3.8,2.8H3.4c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.2,0.1l0,0C2.6,3,2.6,3,2.5,3.1
	c0,0-0.1,0.1-0.1,0.1H2.1c-0.2,0-0.4,0.2-0.4,0.4V5c0,0.2,0.2,0.4,0.4,0.4h1.2h1.2c0.2,0,0.4-0.2,0.4-0.4V3.7c0-0.1,0-0.1-0.1-0.1
	s-0.1,0-0.1,0.1l0,0V5c0,0.1-0.1,0.2-0.2,0.2H3.3H2.1C2,5.2,1.9,5.1,1.9,5V3.5c0-0.1,0.1-0.2,0.2-0.2h0.4c0.1,0,0.2-0.1,0.2-0.1
	C2.7,3.1,2.7,3,2.8,3c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.4,0,0.5,0C3.9,2.9,3.9,2.9,3.8,2.8
	C3.9,2.9,3.9,2.8,3.8,2.8L3.8,2.8L3.8,2.8z"
                />
                <path
                  d="M3.3,3.7C3,3.7,2.8,3.9,2.8,4.2S3,4.7,3.3,4.7s0.5-0.2,0.5-0.5S3.6,3.7,3.3,3.7z M3.3,4.5C3.1,4.5,3,4.4,3,4.2
	C3,4,3.1,3.9,3.3,3.9c0.2,0,0.3,0.1,0.3,0.3C3.6,4.4,3.5,4.5,3.3,4.5z"
                />
              </svg>
            </span>
            <span class="bank-linking-text camera-text">
              Please upload your bank statement
            </span>
          </div>
        </div>
        <div class="col-12" v-else>
          <button type="button" class="btn-upload" @click="addMoreDocument">
            Add More Document
          </button>
        </div>
        <div class="col-12 text-center p-1 mt-1">
          <label style="color: white; font-size: 1rem;  margin-left: 0.5rem">
            Supported file formats are png , jpeg , jpg , pdf <br/>
            Maximum file size = 10 MB
          </label>
        </div>
      </div>
      <!-- next button section -->
      <div class="row icon-space">
        <div class="col-12">
          <button type="button" class="btn-upload" @click="clickSubmit">
            Submit
          </button>
        </div>
      </div>
    </div>

    <!---- MODAL FOR  Error Modal  ---->
    <div>
      <b-modal
        ref="zero-pp-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="zero-pp-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ pp_alert_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideModal('zero-pp-modal')"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <div>
      <b-modal
        ref="uploading-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="uploading-modal"
        centered
      >
        <div class="row text-center">
          <div class="col-12">
            <span class="text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="100"
                height="100"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#ffffff"
              >
                <path
                  d="M34.1,35.1c-0.8-0.8-0.8-2,0-2.8l14.5-14.5c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0.1,0,0.1-0.1c0.2-0.1,0.3-0.1,0.5-0.2
	c0,0,0,0,0,0c0.2,0,0.3,0,0.5,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.5,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.1,0.3,0.2,0.4,0.3
	c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1l14.5,14.5c0.8,0.8,0.8,2,0,2.8c-0.4,0.4-0.9,0.6-1.4,0.6c-0.5,0-1-0.2-1.4-0.6L52,23.9v42
	c0,1.1-0.9,2-2,2s-2-0.9-2-2v-42L36.9,35.1C36.1,35.8,34.9,35.8,34.1,35.1z M83.4,49.2c-1.1,0-2,0.9-2,2v18.5c0,5.1-4.1,9.2-9.2,9.2
	H27.7c-5.1,0-9.2-4.1-9.2-9.2V50.9c0-1.1-0.9-2-2-2s-2,0.9-2,2v18.8c0,7.2,5.9,13.1,13.1,13.1h44.6c7.2,0,13.1-5.9,13.1-13.1V51.2
	C85.4,50.1,84.5,49.2,83.4,49.2z"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="row text-upload text-center">
          <div class="col-12">
            <span class="success-text">{{uploadingMsg}}</span>
          </div>
        </div>
      </b-modal>
      <!-- loader modal end -->
    </div>
    <div>
      <b-modal
        ref="email-success-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="email-success-modal"
        centered
        title="BootstrapVue"
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="pin-success-top-spacing"></div>
            <div class="row" style="margin-bottom: 5px">
              <div class="col-4"></div>
              <div class="col-4">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 125"
                  style="enable-background: new 0 0 100 125"
                  xml:space="preserve"
                  height="80"
                  width="80"
                  fill="#1b9142"
                  class="succes-svg-padding"
                >
                  <g>
                    <g>
                      <path
                        d="M50.2,99.7c-27,0-49-21.9-49-48.9S23.2,2,50.2,2s49,21.9,49,48.9S77.2,99.7,50.2,99.7z M50.2,7.1
			C26,7.1,6.4,26.7,6.4,50.8S26,94.6,50.2,94.6S94.1,75,94.1,50.8S74.4,7.1,50.2,7.1z"
                      />
                    </g>
                    <g>
                      <polygon
                        points="47.4,69.2 29,52.3 32.5,48.5 46.6,61.4 67,31.7 71.3,34.7 		"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="col-4"></div>
            </div>
            <div class="d-block text-center">
              <label class="success-popup-style"
                >Uploaded Bank Statement successfully</label
              >
            </div>
            <div class="success-bottom-spacing"></div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</div>
</template>
<script src="pdf.js"></script>
<script src="pdf.worker.js"></script>
<script>
import accountAPI from "../../api/account.js";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
export default {
  name: "Pay",
  components: {
    Loading,
    CanPayLoader
  },
  methods: {
    showModal(modal) {
      this.$refs[modal].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
      // this.$router.push("/setting").catch((err) => {});
    },
    addMoreDocument() {
      this.addMore = false;
    },
    openCamera() {
      document.getElementById("open_camera").click();
    },
    onFileChange(e) {
      let self = this;
      var imageFront = this.$refs.camera.files[0];
      if (this.$refs.camera.files[0].size > 10 * 1024 * 1024) {
        self.pp_alert_message = "Error : Exceeded size 10MB";
        self.showModal("zero-pp-modal");
        return false;
      }
      if (imageFront == null) {
        self.pp_alert_message = "Please upload file";
        self.showModal("zero-pp-modal");
      }
      if (this.$refs.camera.files[0].type == "application/pdf") {
        var isPdf = true;
      }else{
        var isPdf = false;
      }
      var fileName = this.$refs.camera.files[0].name;

      self.uploadingMsg = 'Uploading...';
      self.showModal("uploading-modal");
      /*Initialize the form data*/
      let formData = new FormData();
      formData.append("fileFront", imageFront);

      accountAPI
        .uploadDocumentToAws(formData)
        .then((response) => {
          if (response.code == 200) {
            self.uploaded_files.push({ awsFileName: response.data.aws_file_name ,url : response.data.temp_url , isPdf: isPdf, fileName:fileName});
            if(self.uploaded_files.length > 0){
              self.addMore = true;
            }
            if(self.uploaded_files.length > 5){
              self.upload = false;
            }
          }else if (response.code == 401) {
            alert(response.message);
            self.$router.go();
          }
          self.hideModal("uploading-modal");
        })
        .catch((err) => {
          self.hideModal("uploading-modal");
          self.pp_alert_message = err.response.data.message;
          self.showModal("zero-pp-modal");
        });
    },
    // changing the content of the screen
    moveToUploadDocument() {
      this.screen = "";
    },
    // this function uploades the pictures to the server and proceeds to next step
    clickSubmit() {
      let self = this;
      if(self.uploaded_files.length == 0){
        this.pp_alert_message = "Please upload file";
        this.showModal("zero-pp-modal");
        return false;
      }
      self.uploadingMsg = 'Submitting...';
      self.showModal("uploading-modal");
      var request = {
        uploaded_files: self.uploaded_files
      };
      accountAPI
        .uploadDocument(request)
        .then((response) => {
          localStorage.setItem("required_upload_document", 0);
          self.hideModal("uploading-modal");
          self.showModal("email-success-modal");
          if (localStorage.getItem("show_upload_documents_modal")) {
            localStorage.removeItem("show_upload_documents_modal");
          }
          setTimeout(function () {
            self.$router.push("/pay").catch((err) => {});
          }, 1400);

          if (response.code == 401) {
            alert(response.message);
            self.$router.go();
          }
        })
        .catch((err) => {
          self.hideModal("uploading-modal");
          this.pp_alert_message = err.response.data.message;
          this.showModal("zero-pp-modal");
        });
    },
    /**
     * Success Modal pops up on clicking the okay button it will redirect to the landing screen
     */
    clickOK() {
      window.location.replace("/");
    },
    hideSuccessModal() {
      this.$refs["email-success-modal"].hide();
    },
  },
  // this function uploades the pictures to the server and proceeds to next step

  data() {
    let self = this;
    return {
      whichImage: "frontside",
      mediaStream: null,
      isLoading: false,
      fullPage: true,
      upload: true,
      addMore: false,
      uploadingMsg: 'Uploading...',
      uploaded_files: [],
      pp_alert_message: null,
      isPdf: false,
      fileName: null,
    };
  },
  created() {},
  mounted() {
    var element = document.getElementsByClassName("content-wrap");
    var elementTwo = document.getElementsByClassName("drawer-black-content");
    elementTwo[0].style.setProperty("background-color", "#149240");
    console.log(elementTwo);
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
    }
    this.$root.$emit("loginapp", [""]);
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
  },
};
</script>

<style lang="scss">
#zero-pp-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#email-success-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 0.7rem;
  margin: 0.7rem;
}
</style>
